body, html {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Montserrat", sans-serif;
  background-image: url(./Images/Frame\ 1512.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white !important;
  box-shadow: 0 -200px 600px -120px rgb(0, 173, 94, 0.2) inset;
  /* animation: background 8s infinite alternate; */
  width: 100%;
}

@keyframes background {
  50% {
    background: linear-gradient(90deg, #112e1c 0%, rgba(23, 71, 41, 1) 100%);
    ;
    box-shadow: 0 400px 400px -100px rgb(0, 173, 94, 0.2) inset;
  }
}

* {
  padding: 0px;
  margin: 0px;
  border: none;
  user-select: none
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Links */

a,
a:link,
a:visited {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: none;
}

/* Common */

aside,
nav,
footer,
header,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
}

ul,
ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

/* Form */

input,
textarea,
button,
select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button,
input[type="submit"] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus,
input:active,
button:focus,
button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

html {
  overflow-y: scroll;
}

html {
  --tg-viewport-height: 100vh;
  --tg-viewport-stable-height: 100vh;
  overflow-y: scroll;
}

.css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root {
  background-color: rgb(0, 173, 94, 0.1) !important;
}