.App {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 100vh;
  width: 372px;
  color: white !important;
}

.Header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}

.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Page {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.Page {
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
}

.footer-container {
  width: 100%;
}

.title {
  font-size: 20px;
  font-weight: 600;
}

.MainPage-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 326px;
  row-gap: 38px;
  column-gap: 20px;
}

.MainPage-menu-item {
  width: 144px;
  height: 44px;
  border-radius: 10px 2px 10px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(0, 173, 94);
  background: linear-gradient(90deg, rgba(0, 173, 94, 0.4) 0%, rgba(20, 100, 50, 0.4) 100%);
  font-weight: 800;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.MainPage-menu-title {
  width: 308px;
  font-size: 16px;
  font-weight: 600;
}

.full {
  width: 308px;
}

img {
  width: 200px;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate360 16s linear infinite;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.85);
}

.overlay-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 85%;
  text-align: center;
}

.close-btn img {
  width: 50px;
}

.map {
  width: 100%;
  border-radius: 10px;
}